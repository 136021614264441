
import { Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import AppNotification from "@/components/AppNotification.vue";
import { CompareFav } from "@/mixins/compare-fav.mixin";
import { School } from "@/models/interface";
import { metaData } from "@/assets/js/common-meta-info";
import { setMetaInfo } from "@/assets/js/meta-info-config";
import { ProfileType, profileTypeArray } from "@/models/schoolfinder.enums";
import CommonMixin from "@/mixins/common.mixin";
import GoogleMapMixin from "../mixins/google-map.mixin";

@Component({
  components: { AppNotification },
  computed: { ...mapState("app", ["school", "mobileView", "tabletView"]) },
})
export default class FavoriteList extends Mixins(
  GoogleMapMixin,
  CompareFav,
  CommonMixin
) {
  private favList: School[] = [];
  mobileView!: boolean;
  tabletView!: boolean;

  ProfileType = ProfileType;
  profileTypeArray = profileTypeArray;

  mounted() {
    setMetaInfo({ title: "Favorite Schools" });
    this.favList = this.getObjectFromSessionStorage("favList");
    this.favList = this.favList.sort((x,y) => {
      return x.id - y.id
      });
  }

  removeFav(school: School) {
    this.favList.forEach((value, index) => {
      if (value.entityId == school.entityId) this.favList.splice(index, 1);
    });

    this.removeFromFavList(school);
  }

  addFavToCompareList(school: School) {
    if (this.latestCompareCount() == this.maxNumberOfComparePossible) {
      this.$bvToast.show("maxCompareLimit");
    } else {
      this.favList.filter((s) => s.entityId == school.entityId)[0].isToCompare =
        true;
      this.addToCompareList(school, school.schoolIndex);
    }
  }

  removeFavFromCompareList(school: School) {
    this.favList.filter((s) => s.entityId == school.entityId)[0].isToCompare =
      false;
    this.removeFromCompareList(school);
  }

 navigateToSchoolDetail(school: School, schoolIndex: number) {
    let url;
    if (this.earlyChildhood(school))
      url = "/schools/" + school.entityId + "/ec-school-about/" + school.name;
    else
      url = "/schools/" + school.entityId + "/about-our-school/" + school.name;

    console.log(url);
    school.schoolIndex = schoolIndex;
    sessionStorage.setItem("selected-school", JSON.stringify(school));
    this.$router.push(url);
  }

  get resultCardContainerBorder() {
    return {
      "border-right-1": this.tabletView,
      "border-0": this.mobileView,
    };
  }

  get resultCardK12ECLayout() {
    return {
      col: this.tabletView,
      "d-flex flex-row": this.mobileView,
    };
  }

  get schoolHeaderContainerLayout() {
    return {
      "mb-3": this.tabletView,
      "": this.mobileView,
    };
  }
  get roadMilesLayout() {
    return {
      "ml-auto": this.tabletView,
      "": this.mobileView,
    };
  }

  k12Both(school: School) {
    return (
      school.profileType === this.profileTypeArray[this.ProfileType.K12].name ||
      school.profileType === this.profileTypeArray[this.ProfileType.Both].name
    );
  }
  earlyChildhood(school: School) {
    return (
      school.profileType ===
      this.profileTypeArray[this.ProfileType.EarlyChildhood].name
    );
  }
  both(school: School) {
    return (
      school.profileType === this.profileTypeArray[this.ProfileType.Both].name
    );
  }

  get schoolHeaderMetadataLayout() {
    return {
      "": this.tabletView,
      "flex-column": this.mobileView,
    };
  }
}
